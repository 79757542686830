const APPNAME = "";
// export const STAGE = `https://${APPNAME}.devtomaster.com`
export const STAGE = `http://localhost:5000/api`;
export const DEV = `https://tariqat-backend.vercel.app/api`;
// export const PROD = `http://tariqatbackendv1-env-2.eba-dtqh3qty.ap-south-1.elasticbeanstalk.com/api`;
export const PROD = `https://y3jqyaaqwe.us-east-1.awsapprunner.com/api`;
//for env swithching
export const ENV = `${PROD}`;
export const ADMIN = `${ENV}`;
export const LOGIN_API_ROOT = `${PROD}`;

export const ADMIN_LOGIN_API = `/admin/sign-in/`;
export const LIVE_BROADCAST_API = `/live-broadcast/`;
export const LIVE_BROADCAST_CATEGORY_API = `/playlist-category/`;
export const LIVE_BROADCAST_SUB_CATEGORY_API = `/playlist-sub-category/`;
export const SALAQEEN_API = `/saliqeen/`;
export const EVENT_API = `/event/`;
export const EVENTS_API = `/events/`;
export const LIVE_AUDIO_API = `/live-audio/`;
export const RECENT_EVENTS_API = `/recent-events/`;
export const UPCOMING_EVENTS_API = `/upcoming-events/`;
export const LIBRARY_API = `/book/`;
export const FAQ_API = `/faq/`;
export const FAQ_CATEGORIES_API = `/faq-categories/`;
export const FAQ_CATEGORY_API = `/faq-category/`;
export const REPLY_API = `/reply/`;
export const ITIKAAF_REGISTRATION_API = `/atikaaf/`;
export const FILE_UPLOAD_API = `/upload-single/`;
export const FILE_DOWNLOAD_API = `/download/`;
export const CONTACT_US_API = `/contact-us/`;
export const PROGRAM_REGISTRATION_FORM_API = `/program-registration/`;

export const LOGIN_API = `/management/login/`;
export const SIGNUP = `/management/signup/`;

export const FLOORPLANAPI = `/masjid`;
export const CREATEFLOORPLANAPI = `/masjid`;


